import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
interface Props {
  to: string;
  children: React.ReactNode;
  isMenu?: boolean;
  label?: string;
}

const InternalLink = styled(Link)`
  position: relative;
  color: var(--link-color);
  font-weight: 400;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  &:visited {
    color: var(--link-color);
    font-weight: 400;
  }
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0;
    right: 0;
    background-color: var(--link-color);
    width: 0;
    transition: all 200ms ease-in-out;
  }
  &:hover::after {
    position: absolute;
    width: 100%;
    left: 0;
  }
`;

const ExeternalLink = styled.a`
  position: relative;
  color: var(--link-color);
  font-weight: 400;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  &:visited {
    color: var(--link-color);
    font-weight: 400;
  }
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0;
    right: 0;
    background-color: var(--link-color);
    width: 0;
    transition: all 200ms ease-in-out;
  }
  &:hover::after {
    position: absolute;
    width: 100%;
    left: 0;
  }
`;

const MenuLink = styled(Link)`
  color: var(--text-color);
  text-decoration: none;
`;

export const UniversalLink = ({ to, children, isMenu, label }: Props) => {
  // check if internal
  const isInternal = /^\/(?!\/)/.test(to);

  if (isMenu) {
    return <MenuLink to={to}>{children}</MenuLink>;
  }

  if (isInternal) {
    return (
      <InternalLink aria-label={label} to={to}>
        {children}
      </InternalLink>
    );
  }

  return (
    <ExeternalLink href={to} target='_blank' rel='noopener noreferrer'>
      {children}
    </ExeternalLink>
  );
};
