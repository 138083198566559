import React from 'react';
import styled from 'styled-components';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

// Hooks
import { useTheme } from '../context/ThemeContext';
import { useSystemColor } from '../hooks/useSystemColor';

const ToggleWrapper = styled.button`
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
`;

const ToggleButton = styled(FontAwesomeIcon)`
  color: var(--icon-color);
`;

export const ThemeSelector = () => {
  const { theme, setTheme } = useTheme(); // The current theme
  const prefersDarkMode = useSystemColor(); // @media prefers dark mode
  let isDark = false;

  if (!theme) {
    prefersDarkMode ? (isDark = true) : (isDark = false);
  } else {
    isDark = theme === 'dark' ? true : false;
  }

  return (
    <div>
      <ToggleWrapper
        role='button'
        aria-label={`Toggle ${isDark ? 'light' : 'dark'} mode`}
        onClick={setTheme}
      >
        <ToggleButton focusable icon={isDark ? faMoon : faSun} />
      </ToggleWrapper>
    </div>
  );
};
