import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useSystemColor } from '../hooks/useSystemColor';

enum ThemeEnums {
  light = 'light',
  dark = 'dark',
}

type ThemeTypes = ThemeEnums.dark | ThemeEnums.light;

interface Props {
  children: React.ReactNode;
}

interface IContext {
  theme?: keyof typeof ThemeEnums;
  setTheme?: () => void;
}

const LocalStateContext = createContext<IContext>({});
const LocalStateProvider = LocalStateContext.Provider;

export const ThemeContext = ({ children }: Props) => {
  const [theme, setTheme] = useState<ThemeTypes>();
  const prefersDarkMode = useSystemColor();
  const [setValue, getValue] = useLocalStorage();

  useEffect(() => {
    const localTheme = getValue('theme');
    if (localTheme && localTheme in ThemeEnums) {
      // We have to ignore this line becuase the
      // TS compiler doesn't know that we already
      // checked the type of localTheme
      // @ts-ignore
      setTheme(() => localTheme);
    }
  }, []);

  function toggleTheme() {
    if (theme === 'light') {
      setTheme(() => ThemeEnums.dark);
      setValue('theme', ThemeEnums.dark);
    } else if (theme === 'dark') {
      setTheme(() => ThemeEnums.light);
      setValue('theme', ThemeEnums.light);
    } else if (prefersDarkMode) {
      setTheme(() => ThemeEnums.light);
      setValue('theme', ThemeEnums.light);
    } else {
      setTheme(() => ThemeEnums.dark);
      setValue('theme', ThemeEnums.dark);
    }
  }

  return (
    <LocalStateProvider value={{ theme: theme, setTheme: toggleTheme }}>
      {children}
    </LocalStateProvider>
  );
};

export const useTheme = () => {
  const all = useContext(LocalStateContext);
  return all;
};
