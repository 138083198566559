// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-todo-tsx": () => import("./../../../src/pages/todo.tsx" /* webpackChunkName: "component---src-pages-todo-tsx" */),
  "component---src-templates-blog-template-mdx-tsx": () => import("./../../../src/templates/blogTemplateMDX.tsx" /* webpackChunkName: "component---src-templates-blog-template-mdx-tsx" */)
}

