import React from 'react';
import styled from 'styled-components';
import { UniversalLink } from './UniversalLink';

const FooterWrapper = styled.footer`
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 1rem;
  padding: 1rem;
  margin-top: 5rem;
  div,
  small {
    text-align: center;
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <div>
        <div>
          <small>&copy;genehenson.com {new Date().getFullYear()}</small>
        </div>
        <div>
          <small>
            Made with ❤️ on{' '}
            <UniversalLink to={'https://en.wikipedia.org/wiki/Earth'}>
              🌍
            </UniversalLink>{' '}
            . Build with{' '}
            <UniversalLink to={'https://www.gatsbyjs.com/'}>
              GatsbyJS
            </UniversalLink>
            . Hosted on{' '}
            <UniversalLink to={'https://render.com/'}>Render</UniversalLink>
          </small>
        </div>
      </div>
    </FooterWrapper>
  );
};
