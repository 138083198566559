import React from 'react';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { UniversalLink } from '../components/UniversalLink';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { SEO } from '../components/SEO';

// Global styles and normalize
import '../styles/master.css';

import { ThemeContext } from '../context/ThemeContext';

// Shortcodes for MDX
const shortcodes = { UniversalLink };

interface Props {
  children: React.ReactNode;
}

const SiteWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`;

export const MainLayout = ({ children }: Props) => {
  return (
    <ThemeContext>
      <MDXProvider components={shortcodes}>
        <SiteWrapper>
          <SEO title='Hey there' />
          <Header />
          <main>{children}</main>
          <Footer />
        </SiteWrapper>
      </MDXProvider>
    </ThemeContext>
  );
};
