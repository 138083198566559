import React from 'react';
import styled from 'styled-components';
import { UniversalLink } from './UniversalLink';

interface Props {}

const NavWrapper = styled.nav``;

const NavItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
`;

const NavItem = styled.li``;

export const MainNav = (props: Props) => {
  return (
    <NavWrapper>
      <NavItems>
        <NavItem>
          <UniversalLink isMenu to={'/blog'}>
            blog
          </UniversalLink>
        </NavItem>
        <NavItem>
          <UniversalLink isMenu to={'/todo'}>
            todo
          </UniversalLink>
        </NavItem>
      </NavItems>
    </NavWrapper>
  );
};
