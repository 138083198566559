import React from 'react';
import styled from 'styled-components';
import { MainNav } from './MainNav';
import { UniversalLink } from './UniversalLink';
import { ThemeSelector } from '../components/ThemeSelector';

interface Props {}

const StyledHeader = styled.header`
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  align-content: center;
  box-shadow: 0 1px 1px -1px var(--shadow-color);
  & div {
    display: grid;
    grid-auto-flow: column;
    justify-self: end;
    align-content: center;
    align-items: center;
    gap: 1rem;
  }
`;

export const Header = ({}: Props) => {
  return (
    <StyledHeader>
      <span>
        <UniversalLink isMenu to={'/'}>
          genehenson.com
        </UniversalLink>
      </span>
      <div>
        <MainNav />
        <ThemeSelector />
      </div>
    </StyledHeader>
  );
};
