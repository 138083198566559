import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTheme } from '../context/ThemeContext';
import { useSystemColor } from '../hooks/useSystemColor';

interface Props {
  title?: string;
  children?: React.ReactNode;
  location?: any;
  description?: string;
  image?: string;
}

export const SEO = ({
  title,
  children,
  location,
  description,
  image,
}: Props) => {
  const theme = useTheme();
  const prefersDarkMode = useSystemColor();
  const htmlColor = theme.theme || (prefersDarkMode && 'dark') || false;
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          siteUrl
          title
          twitter
        }
      }
    }
  `);
  const imgPath = image
    ? site.siteMetadata.siteUrl + image
    : site.siteMetadata.siteUrl + '/favicon.svg';
  return (
    <Helmet titleTemplate={`%s ▶️ ${site.siteMetadata.title}`}>
      {htmlColor ? <html lang='en' color={htmlColor} /> : <html lang='en' />}
      <title>{title}</title>
      {/* Fav Icons */}
      <link rel='icon' type='image/svg+xml' href='/favicon.svg' />
      {/* Meta */}
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta http-equiv='Content-Type' content='text/html;charset=UTF-8' />
      <meta
        name='description'
        content={description || site.siteMetadata.description}
      />
      {/* Open Graph */}
      {location && (
        <meta property='og:url' content={location.href} key='ogurl' />
      )}
      <meta property='og:title' content={title} key='ogtitle' />
      <meta property='og:type' content='website' key='ogtype' />
      <meta property='og:image' content={imgPath} key='ogimage' />
      (
      <meta
        property='og:site_name'
        content={site.siteMetadata.title}
        key='ogsitename'
      />
      ) (
      <meta
        property='og:description'
        content={description || site.siteMetadata.description}
        key='ogdescription'
      />
      {/* Twitter */}
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@genehenson' />
      <meta name='twitter:creator' content='@genehenson' />){children}
    </Helmet>
  );
};
